import API from '@aws-amplify/api';
import React from 'react';

interface ResultBoxProps {
  sessionId: string;
}

interface ResultBoxState {
  isCalculated: boolean;
  calculateResult: number;
}
/**
 * Nəticə komponenti
 * Imtahan barəsində ümumi informasiya
 */
class Result extends React.Component<ResultBoxProps> {
  state: ResultBoxState;
  constructor(props: never) {
    super(props);
    this.state = {
      isCalculated: false,
      calculateResult: 0,
    };
    this.calcExamSession = this.calcExamSession.bind(this);
  }
  /**
   * Backend dən imtahan nəticəsinin hesablanması
   */
  calcExamSession() {
    const sessionId: string = this.props.sessionId;
    // call https://surf.cantyquizservice.dev.ozunoyren.org/session/HEC8V3T/calculate
    API.get('QUIZSURF_API', 'session/' + sessionId + '/calculate', []).then(
      (result) => {
        this.setState({
          isCalculated: true,
          calculateResult: result.calculateResult,
        });
      },
    );
  }

  componentDidMount() {
    this.calcExamSession();
  }
  render() {
    const { calculateResult, isCalculated } = this.state;
    return (
      <div className="informationPanel">
        <h2>Sınaq imtahanı yekunlaşmışdır</h2>
        <div className="examResult">
          {isCalculated ? (
            <p>
              Sizin nəticə <b>{calculateResult}</b>
            </p>
          ) : (
            <p>Nəticə hesablanır</p>
          )}
        </div>
      </div>
    );
  }
}

export default Result;
